<template>
  <div class="row wrapper border-bottom page-heading">
    <div class="col-lg-10">
      <h2 class="mb-0">{{ $t("navigations.onboarding client") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <ul class="nav nav-tabs" role="tablist">
          <li v-for="(tab, index) in filteredTabs" :key="index">
            <a
              :class="{ activeTab: showBlock[index] }"
              @click="setActiveTab(index)"
              data-toggle="tab"
            >
              {{ $t(tab.label) }}
            </a>
          </li>
        </ul>
        <div role="tabpanel" v-for="(tab, index) in tabs" :key="index" v-show="showBlock[index]">
          <div class="ibox-content">
             <!-- tab 1 start -->
            <div v-if="index === 0">
              <!-- <p>{{ $t("navigations.my profile content") }}</p> -->
              <div class="form-group row">
                <div class="col-lg-8 my-2">
                  <label class="col-form-label">{{ $t("navigations.client/company name") }}</label>
                  <input type="text" class="form-control" placeholder="" />
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.client id") }}</label>
                  <input type="text" class="form-control"  />
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.primary contact name") }}</label>
                  <input type="text" class="form-control"  />
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.client contact email") }}</label>
                  <input type="text" class="form-control"  />
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.client contact number") }}</label>
                  <input type="text" class="form-control"  />
                </div>
                        <div class="col-lg-12 my-2">
                          <h4 class="mt-2 mb-0">Branding</h4>
                          <hr class="my-1">
                        </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                    <label for="uploadLogo">Upload Logo</label>
                      <input type="file" id="uploadLogo" class="form-control" accept=".png, .jpg, .jpeg" onchange="validateLogoFile()">
                      <small class="form-text text-muted">Accepted formats: PNG, JPG. Maximum file size: 2MB.</small>
                    <div id="logoError" class="text-danger" style="display: none;">Invalid file format or size. Please upload a valid PNG or JPG file.</div>
                  </div>
                </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label for="primaryThemeColor">Select Primary Theme Color</label>
                        <select id="primaryThemeColor" class="form-select">
                            <option value="">Select a color</option>
                            <option value="red">Red</option>
                            <option value="blue">Blue</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label for="secondaryThemeColor">Select Secondary Theme Color</label>
                        <select id="secondaryThemeColor" class="form-select">
                            <option value="">Select a color</option>
                            <option value="red">Red</option>
                            <option value="blue">Blue</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                        </select>
                    </div>
                </div>
                        <div class="col-lg-12 my-2">
                          <h4 class="mt-2 mb-0">Affiliate and Associate</h4>
                          <hr class="my-1">
                        </div>
                        <div class="col-lg-6 my-2">
                      <label class="col-form-label">Affiliate/Associate Name</label>
                      <input type="text" class="form-control" placeholder="Enter Affiliate Name" />
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Type of Affiliation</label>
                      <select class="form-select">
                        <option value="">Select Type</option>
                        <option value="vendor">Vendor</option>
                        <option value="partner">Partner</option>
                        <option value="supplier">Supplier</option>
                      </select>
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Contact Details</label>
                      <input type="text" class="form-control" placeholder="Enter Contact Details" />
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Service Areas</label>
                      <input type="text" class="form-control" placeholder="Enter Contact Details" />
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Onboarding Status</label>
                      <select class="form-select">
                        <option value="">Select Status</option>
                        <option value="inProgress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="pending">Pending</option>
                      </select>
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Agreement Signed</label>
                      <select class="form-select">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>               
                <div class="col-lg-12 mt-2 d-flex justify-content-lg-end">
                  <button class="save_btn py-1 px-4" type="button">Save</button></div>
              </div>
            </div>
          <!-- tab 1 end -->
          <!-- tab 2 start -->
          <div v-else-if="index === 1">
              <div class="form-group row">
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label for="defaultStudyParameters">Default Study Parameters</label>
                        <select id="defaultStudyParameters" class="form-select">
                            <option value="">Select Study Parameter</option>
                            <option value="parameter1">Randomization</option>
                            <option value="parameter2">Blinding/Masking</option>
                            <option value="parameter3">Crossover Design</option>
                            <option value="parameter4">Open-Label Study</option>
                        </select>
                  </div>
                </div>
                <div class="col-lg-4 my-2">
                    <div class="form-group">
                        <label for="standardOperatingProcedures">Standard Operating Procedures</label>
                        <select id="standardOperatingProcedures" class="form-select">
                            <option value="">Select SOP</option>
                            <option value="sop1">Participant Recruitment and Screening</option>
                            <option value="sop2">Informed Consent Process</option>
                            <option value="sop3">Clinical Data Collection and Management</option>
                            <option value="sop4">Safety Monitoring and Adverse Event Reporting</option>
                            <option value="sop5">Site Selection and Initiation</option>
                            <option value="sop6">Regulatory Compliance and Submissions</option>
                            <option value="sop7">Study Protocol Development and Amendments</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label for="clinicalTrialStandards">Clinical Trial Standards</label>
                        <select id="clinicalTrialStandards" class="form-select">
                            <option value="">Select Trial Standard</option>
                            <option value="standard1">FDS</option>
                            <option value="standard2">ICH-GCP</option>
                            <option value="standard3">HIPAA</option>
                            <option value="standard4">HHS Regulations</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-12 my-2">
                    <h4 class="mt-2 mb-0">Service Scope</h4>
                    <hr class="my-1">
                </div>
                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Service Category</label>
                  <select class="form-select">
                    <option value="">Select Service Category</option>
                    <option value="monitoring">Monitoring</option>
                    <option value="dataManagement">Data Management</option>
                    <option value="siteSelection">Site Selection</option>
                    <option value="regulatory">Regulatory Submissions</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Study Phase</label>
                  <select class="form-select">
                    <option value="">Select Study Phase</option>
                    <option value="phase1">Phase I</option>
                    <option value="phase2">Phase II</option>
                    <option value="phase3">Phase III</option>
                    <option value="phase4">Phase IV</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Data Collection Method</label>
                  <select class="form-select">
                    <option value="">Select Method</option>
                    <option value="ecrf">eCRF</option>
                    <option value="paperBased">Paper-Based</option>
                    <option value="hybrid">Hybrid</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Regions of Operation</label>
                  <select class="form-select">
                    <option value="">Select Region</option>
                    <option value="northAmerica">North America</option>
                    <option value="europe">Europe</option>
                    <option value="asia">Asia</option>
                    <option value="global">Global</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Reporting Requirements</label>
                  <input type="text" class="form-control" placeholder="Enter Requirements" />
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Risk Management Plan</label>
                  <select class="form-select">
                    <option value="">Select Plan Type</option>
                    <option value="basic">Basic</option>
                    <option value="advanced">Advanced</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Quality Assurance Checks</label>
                  <select class="form-select">
                    <option value="">Select Frequency</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Service Timeline (Months)</label>
                  <input type="number" class="form-control" placeholder="Enter Duration" />
                </div>
                <div class="col-lg-12 my-2">
                    <h4 class="mt-2 mb-0">EHR Data Management</h4>
                    <hr class="my-1">
                </div>
                <div class="col-lg-6 my-2">
                    <label for="uploadFile">Upload EHR Data File</label>
                    <input type="file" id="uploadFile" class="form-control" accept=".pdf, .txt"/>
                    <!-- <small class="form-text text-muted">Supports Excel/CSV/JSON.</small> -->
                </div>
                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Data Cleaning Preferences</label>
                  <select class="form-select">
                    <option value="">Select</option>
                    <option value="daily">Automated</option>
                    <option value="weekly">Manual</option>
                  </select>
                </div>
                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Data Standards</label>
                  <select class="form-select">
                      <option value="">Select</option>
                      <option value="HL7">HL7 (Health Level Seven)</option>
                      <option value="FHIR">FHIR (Fast Healthcare Interoperability Resources)</option>
                      <option value="CDISC">CDISC (Clinical Data Interchange Standards Consortium)</option>
                      <option value="SDTM">SDTM (Study Data Tabulation Model)</option>
                      <option value="ADaM">ADaM (Analysis Data Model)</option>
                      <option value="ODM">ODM (Operational Data Model)</option>
                      <option value="eCRF">eCRF (Electronic Case Report Form Standards)</option>
                      <option value="LOINC">LOINC (Logical Observation Identifiers Names and Codes)</option>
                      <option value="SNOMED_CT">SNOMED CT (Systematized Nomenclature of Medicine - Clinical Terms)</option>
                      <option value="ICD_10_11">ICD-10/ICD-11 (International Classification of Diseases)</option>
                      <option value="CPT">CPT (Current Procedural Terminology)</option>
                      <option value="RxNorm">RxNorm (Standardized Naming System for Medications)</option>
                      <option value="UMLS">UMLS (Unified Medical Language System)</option>
                      <option value="HIPAA">HIPAA (Health Insurance Portability and Accountability Act - Data Privacy)</option>
                      <option value="DICOM">DICOM (Digital Imaging and Communications in Medicine)</option>
                      <option value="GUDID">GUDID (Global Unique Device Identification Database)</option>
                      <option value="ISO_IEEE_11073">ISO/IEEE 11073 (Medical Device Communication Standards)</option>
                      <option value="BRIDG">BRIDG (Biomedical Research Integrated Domain Group Model)</option>
                      <option value="MedDRA">MedDRA (Medical Dictionary for Regulatory Activities)</option>
                      <option value="CCDA">CCDA (Consolidated Clinical Document Architecture)</option>
                      <option value="E2B">E2B (ICH Guideline for Electronic Transmission of Individual Case Safety Reports)</option>
                      <option value="OMOP">OMOP (Observational Medical Outcomes Partnership Common Data Model)</option>
                  </select>
                </div>
                <div class="col-lg-12 mt-2 d-flex justify-content-lg-end">
                  <button class="save_btn py-1 px-4" type="button">Save</button></div>
                </div>
            </div>
            <!-- tab 2 end -->
            <!-- tab 3 start -->
            <div v-else-if="index === 2">
              <div class="form-group row">
                <div class="col-lg-12 my-2">
                  <label class="col-form-label">Billing Address</label>
                  <input type="text" class="form-control"  />
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">Tax ID/Registration Number</label>
                  <input type="text" class="form-control"  />
                </div>
                <div class="col-lg-4 my-2">
                    <div class="form-group">
                        <label class="col-form-label">Payment Terms</label>
                        <select id="standardOperatingProcedures" class="form-select">
                            <option value="">Select</option>
                            <option value="sop1">Net 30</option>
                            <option value="sop2">Net 60</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label class="col-form-label">Preferred Currency</label>
                        <select id="clinicalTrialStandards" class="form-select">
                            <option value="">Select</option>
                            <option value="standard1">USD</option>
                            <option value="standard2">EUR</option>
                            <option value="standard3">GBP</option>
                            <option value="standard4">INR</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-12 mt-2 d-flex justify-content-lg-end">
                  <button class="save_btn py-1 px-4" type="button">Save</button></div>
                </div>
            </div>   
            <!-- tab 3 end -->
          </div>
        </div>
        <!-- Listing Table Section -->
        <div class="ibox mt-4">
          <div class="ibox-title style_2">
            <h5>Onboarded Clients</h5>
          </div>
          <div class="ibox-content">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Client Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(client, index) in clients" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ client.name }}</td>
                  <td>{{ client.email }}</td>
                  <td>{{ client.phone }}</td>
                  <td>
                    <button class="btn btn-primary btn-sm" @click="viewClientDetails(client)">
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Modal for Viewing Client Details -->
        <div class="modal" tabindex="-1" role="dialog" v-if="selectedClient">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Client Details</h5>
                <button type="button" class="close" @click="closeModal">
                  <span>&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p><strong>Name:</strong> {{ selectedClient.name }}</p>
                <p><strong>Email:</strong> {{ selectedClient.email }}</p>
                <p><strong>Phone:</strong> {{ selectedClient.phone }}</p>
                <p><strong>Address:</strong> {{ selectedClient.address }}</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customerOnboarding from "./customerOnboarding.js"; 

export default {
  name: "customerOnboarding",
  data() {
    return {
      tabs: [
        { label: "General Information", content: "navigations.tab1_content" },
        { label: "Study Configuration", content: "navigations.tab2_content" },
        { label: "Billing", content: "navigations.tab3_content" },
        // { label: "Service Scope", content: "navigations.tab5_content" },
        // { label: "Geographics", content: "navigations.tab6_content" },
        // { label: "Affiliate & Onboarding", content: "navigations.tab7_content" },
        // { label: "EHR Data", content: "navigations.tab8_content" },
      ],
      showBlock: [true, false, false, false, false, false, false, false], // Make Tab 1 active by default
    };
  },
  computed: {
    filteredTabs() {
      // Return all tabs since no specific filtering is required
      return this.tabs;
    },
  },
  methods: {
    setActiveTab(index) {
      this.showBlock = this.showBlock.map((_, i) => i === index);
    },
    async callCustomerOnboarding() {
      // Example of using customerOnboarding functionality
      const response = await customerOnboarding.someFunction();
      console.log(response);
    },
  },
  mounted() {
    this.callCustomerOnboarding(); // Call a function from customerOnboarding.js
  },
};
</script>

<style scoped>
/* Styles for tabs and content */
.nav-tabs {
    border-bottom: 1px solid #6660fe;
}
.nav-tabs li a {
  padding: 5px 20px;
  cursor: pointer;
  border: solid 0px #ffffff;
  border-radius: 10px 10px 0px 0px;
}

.activeTab {
  color: #ffffff !important;
  background-color: #6660fe;
  border-color: #6660fe;
}

.ibox-title {
  background-color: #3c4192;
  color: rgb(95, 95, 95);
  padding: 10px;
}

.ibox-content {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
}
.form-select{
  border-radius: .5rem !important;
  font-family: .8rem !important;
}
.table th,
.table td {
  vertical-align: middle;
}
</style>
